/*
Text colors
*/
.red {
  color: $red;
}
.red-light {
  color: $red-light;
}
.orange {
  color: $orange;
}
.tan {
  color: $tan;
}
.blue-dark {
  color: $blue-dark;
}
.blue-light {
  color: $blue-light;
}
.blue-grey {
  color: $blue-grey;
}
.washed-blue {
  color: $washed-blue;
}
.grey-light {
  color: $grey-light;
}
.white {
  color: $white;
}

/*
Text colors hover
*/
.hover-red:hover,
.hover-red:focus {
  color: $red;
}
.hover-red-light:hover,
.hover-red-light:focus {
  color: $red-light;
}
.hover-orange:hover,
.hover-orange:focus {
  color: $orange;
}
.hover-tan:hover,
.hover-tan:focus {
  color: $tan;
}
.hover-blue-dark:hover,
.hover-blue-dark:focus {
  color: $blue-dark;
}
.hover-blue-light:hover,
.hover-blue-light:focus {
  color: $blue-light;
}
.hover-blue-grey:hover,
.hover-blue-grey:focus {
  color: $blue-grey;
}
.hover-grey-light:hover,
.hover-grey-light:focus {
  color: $grey-light;
}
.hover-white:hover,
.hover-white:focus {
  color: $white;
}

/*
Background colors
*/
.bg-red {
  background-color: $red;
}
.bg-red-light {
  background-color: $red-light;
}
.bg-orange {
  background-color: $orange;
}
.bg-tan {
  background-color: $tan;
}
.bg-blue-dark {
  background-color: $blue-dark;
}
.bg-blue-light {
  background-color: $blue-light;
}
.bg-blue-grey {
  background-color: $blue-grey;
}
.bg-washed-blue {
  background-color: $washed-blue;
}
.bg-grey-light {
  background-color: $grey-light;
}
.bg-white {
  background-color: $white;
}
.bg-mask {
  background-image: url('../../assets/img/mask.png');
}
.bg-blue-light-paper {
  background-color: rgba($blue-light, 0.1);
}

/*
Background colors hover
*/
.hover-bg-red:hover,
.hover-bg-red:focus {
  background-color: $red;
}
.hover-bg-red-light:hover,
.hover-bg-red-light:focus {
  background-color: $red-light;
}
.hover-bg-orange:hover,
.hover-bg-orange:focus {
  background-color: $orange;
}
.hover-bg-tan:hover,
.hover-bg-tan:focus {
  background-color: $tan;
}
.hover-bg-blue-dark:hover,
.hover-bg-blue-dark:focus {
  background-color: $blue-dark;
}
.hover-bg-blue-light:hover,
.hover-bg-blue-light:focus {
  background-color: $blue-light;
}
.hover-bg-blue-grey:hover,
.hover-bg-grey:focus {
  background-color: $blue-grey;
}
.hover-bg-grey-light:hover,
.hover-bg-grey-light:focus {
  background-color: $grey-light;
}
.hover-bg-white:hover,
.hover-bg-white:focus {
  background-color: $white;
}

/*
Border colors
*/
.b--red {
  border-color: $red;
}
.b--red-light {
  border-color: $red-light;
}
.b--orange {
  border-color: $orange;
}
.b--tan {
  border-color: $tan;
}
.b--blue-dark {
  border-color: $blue-dark;
}
.b--blue-light {
  border-color: $blue-light;
}
.b--blue-grey {
  border-color: $blue-grey;
}
.b--grey-light {
  border-color: $grey-light;
}
.b--white {
  border-color: $white;
}

/* svg fill colors */
.fill-red {
  fill: $red;
}
.fill-tan {
  fill: $tan;
}
.fill-green {
  fill: #3e9c67;
}
.fill-blue {
  fill: #1757c4;
}
.fill-yellow {
  fill: #f09733;
}
